@import "/styles/variables";

@layer utilities {
  .button {
    font-size: 14px;
    font-weight: $font-weight-bold;

    .placeholder {
      font-weight: $font-weight-medium;
      color: $color-greyMinus1;
    }
  }
}

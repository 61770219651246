@import "/styles/variables";

@keyframes shimmer {
  0% {
    background-position: -100vw 0;
  }

  100% {
    background-position: 100vw 0;
  }
}

.box {
  background: $color-greyMinus2;
  background: linear-gradient(to right, $color-greyMinus3 0%, $color-greyMinus2 50%, $color-greyMinus3 100%);
  background-size: 100vw;
  border-radius: 5px;

  animation-name: shimmer;
  animation-duration: 15s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.responsiveSquare {
  padding-bottom: 100%;
}

@use "sass:color";

@import "/styles/variables";

$screen-navbar-desktop-medium: 1250px;
$screen-navbar-mobile-medium: 440px;
$screen-navbar-mobile-mini: 380px;

.placeholderPadding {
  @include media("mobile") {
    padding-top: $navbar-height-mobile;
  }

  padding-top: $navbar-height;
}

.container {
  @include media("mobile", "tablet") {
    padding: 0 $space-8;
  }

  position: fixed;
  z-index: $z-index-navbar;
  top: 0;
  right: 0;
  left: 0;

  background: $color-white;
  box-shadow: 0 1px 0 $color-greyMinus3;

  transition:
    background 0.2s ease,
    box-shadow 0.2s ease;

  @include media("desktop", "oversized") {
    --link-spacing: #{$space-16};
  }
  @media (width < $screen-navbar-desktop-medium) {
    --link-spacing: #{$space-12};
  }
  @include media("mobile", "tablet") {
    --link-spacing: #{$space-16};
  }
  @media (width < $screen-navbar-mobile-mini) {
    --link-spacing: #{$space-12};
  }
}

$space-nav-link: var(--link-spacing);
$space-nav-link-half: calc(0.5 * var(--link-spacing));

.homePage {
  &.transparent {
    position: absolute;
  }
}

.transparent {
  background: transparent;
  box-shadow: none;
}

.transparentButton {
  background: transparent;

  &:hover {
    background: transparent;
  }
}

.whileActiveHam {
  z-index: $z-index-generic-modal + 1;
  background: $color-white;
  box-shadow: none;

  // we are using modal(so also overlay) to display ham menu
  // we also want keep navbar visible so we adds zIndex + 1 to place it over the overlay
}

.flex {
  @include media("mobile") {
    height: $navbar-height-mobile;
  }

  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: $navbar-height;

  // Prevent "margin hack" from breaking layout. No left/right margins here to collapse.
  > hr {
    display: none;
  }
}

.left {
  @include media("mobile") {
    margin-right: 0;
  }

  display: flex;
  align-items: center;
}

.right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search {
  display: flex;
  flex: 1;
  align-items: center;

  @include media("desktop", "oversized") {
    margin-right: $space-30;
  }
}

$navbar-link-height: 50px;
$navbar-link-height-mobile: 40px;

.navlink,
.navlist {
  display: flex;
  align-items: center;

  height: $navbar-link-height;
  margin-right: $space-nav-link-half;
  padding: 12px $space-nav-link;

  border-radius: 25px;

  transition: 0.25s background-color;

  &:is(a) {
    // No idea where this slight offset comes from (should be 12px), but this is
    // required to make "License" link line-up with the other header elements
    padding-top: 13.5px;
  }

  &.round,
  &.round > button {
    width: $navbar-link-height;
    height: $navbar-link-height;

    @include media("mobile") {
      width: $navbar-link-height-mobile;
      height: $navbar-link-height-mobile;
    }
  }

  &:focus-within,
  &:focus-visible {
    filter: none;
    outline: 2px solid $color-greyscale-300 !important;
  }

  > svg[data-indicator] {
    .transparent & {
      fill: $color-white;
    }
  }

  ul li a span {
    color: white;
  }

  &:hover,
  &.hover {
    background-color: $color-greyscale-200;

    // Suppress darkening effect of .clickable
    filter: unset;

    .transparent & {
      background-color: color.change($color-black, $alpha: 0.4);
    }
  }
}

.linkMobile {
  margin-right: -$space-4;
}

.spacer {
  margin-right: $space-nav-link;
}

.logo {
  display: flex;
  outline: none;

  svg {
    width: 85px;
    height: 38px;
  }
}

.avatar {
  margin-left: $space-nav-link-half;

  border-radius: 50%;
  outline: solid transparent 4px;

  transition: outline-color;
  transition-delay: 150ms, 200ms; // Must match Tooltip delay

  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a,
  div {
    // Suppress darkening effect of .clickable
    filter: unset !important;
  }

  &:hover {
    // Apply border
    outline-color: $color-greyscale-200;

    .transparent & {
      outline-color: color.change($color-black, $alpha: 0.4);
    }
  }
}

.arrowIcon svg:last-child {
  margin-left: $space-4;
}

.hamBtn {
  margin-right: -$space-8;
}

.darkNavbarBtn {
  color: $color-white;

  svg {
    fill: $color-white;
  }
}

.mobileUpload {
  margin-right: $space-nav-link-half;
  margin-left: 0;
}

.notificationRedDot > span {
  position: relative;

  &::after {
    content: "";

    position: absolute;
    z-index: 1;
    top: -1px;
    right: -8px;

    display: block;

    width: 8px;
    height: 8px;

    background: $color-papaya;
    border-radius: 50%;
  }
}

.signupButton {
  @include media("mobile", "tablet") {
    margin-right: $space-4;
    margin-left: $space-8;
  }
}

.hideOnMediumMobile {
  @include hide-below($screen-navbar-mobile-medium);
}

.hideOnDesktopMedium {
  @include hide-below($screen-navbar-desktop-medium);
}

.hideOnDesktopMediumUp {
  @include hide-above($screen-navbar-desktop-medium);
}

@import "/styles/variables";

.gridContainer {
  position: relative;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap, $space-20);
  align-items: flex-start;
}

@import "/styles/variables";

@layer utilities {
  .divider {
    border-style: solid;
    border-width: 1px 0 0;
  }

  /**
   * Colors that match up to the UI kit
   */

  .color-greyMinus3F7F7F7 {
    border-color: $color-greyMinus3;
  }

  .color-greyMinus2DFDFE0 {
    border-color: $color-greyMinus2;
  }

  .color-greyMinus1BFBFBF {
    border-color: $color-greyMinus1;
  }

  .color-grey7F7F7F {
    border-color: $color-grey;
  }

  .color-greyPlus14A4A4A {
    border-color: $color-greyPlus1;
  }

  .color-midnight2C343E {
    border-color: $color-midnight;
  }

  .color-black000000 {
    border-color: $color-black;
  }

  .color-whiteFFFFFF {
    border-color: $color-white;
  }

  .color-green05A081 {
    border-color: $color-green;
  }

  .color-redD3405C {
    border-color: $color-red;
  }

  // Generate new Tokens class names.
  @include generate-tokencolor-classes("border-color");
}

@import "/styles/variables";

$min-row-height-desktop: 320px;
$min-row-height-tablet: 220px;
$min-row-height-mobile: 140px;
$max-last-item-width: 400px;

.gridItem {
  // This allows each item to grow proportionally according to its aspect ratio
  flex-grow: var(--aspectRatio);
  order: calc(var(--index) + 1);

  aspect-ratio: var(--aspectRatio);
  max-width: 100%;
  min-height: $min-row-height-desktop;

  @include media("tablet") {
    min-height: $min-row-height-tablet;
  }

  @include media("mobile") {
    min-height: $min-row-height-mobile;
  }

  &.lastItemAloneOnRow {
    max-width: min($max-last-item-width, 100%);
    @include media("mobile") {
      max-width: 100%;
    }
  }

  &.expand {
    @include media("mobile", "tablet") {
      width: 100%;
      min-height: 0;
    }
  }

  &.fullWidth {
    width: 100%;
  }
}

@import "/styles/variables";

.icon-burger-close {
  position: relative;

  span {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);

    display: block;

    width: 16px;
    height: 2px;

    background: currentcolor;

    transition: all 0.15s ease;
  }

  span:first-child {
    top: 33%;
  }

  span:last-child {
    top: 66%;
  }

  &.active {
    span {
      top: 50%;
      left: 50%;
      width: 18px;
    }

    span:first-child {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    span:last-child {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

@layer utilities {
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    white-space: break-spaces;
  }

  .childCheck {
    width: 100%;
    max-width: 17px;
    height: 100%;
    max-height: 17px;
  }

  .childText {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
}

@import "/styles/variables";

@mixin option {
  cursor: pointer;

  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  height: auto;
  min-height: 50px;
  padding: 8px 20px;

  color: $color-greyMinus1;
  text-align: left;
  text-decoration: none;
  white-space: nowrap;

  background: $color-white;
  border: none;

  .childTextPrimary {
    margin-right: $space-8;
  }

  &.selected {
    .childTextPrimary {
      color: $color-black;
    }
  }

  &:hover {
    background: $color-greyMinus3;
  }

  &:focus {
    outline: none;
  }

  &:not(:last-child) {
    &::after {
      $distance-to-edge: 20px;

      content: "";

      position: absolute;
      right: $distance-to-edge;
      bottom: 0;
      left: $distance-to-edge;

      height: 1px;
      margin: 0;
      padding: 0;

      background: $color-greyMinus3;
      border: none;
    }
  }
}

@layer utilities {
  .option {
    @include option;
  }

  .optionFlexibleHeight {
    display: block;
    height: auto;
    padding-top: $space-15;
    padding-bottom: $space-15;
  }
}

@import "/styles/variables";

$heroMinHeight: 500px;

:export {
  /* stylelint-disable-next-line property-no-unknown */
  heroMinHeight: $heroMinHeight;
}

.hero {
  position: relative;
  z-index: $z-index-dropdowns;
  width: 100%;
  min-height: $heroMinHeight;

  &::after {
    content: "";

    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0.5;
    background: $color-black;
  }

  .img,
  .video {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}

.content {
  width: 100%;
  max-width: 630px;
}

.subheading {
  @include media("mobile", "tablet") {
    text-align: left;
  }
}

.trendingSearches {
  scrollbar-width: none;
  overflow-x: auto;
  white-space: nowrap;

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .trendingSearchesText {
    display: inline-block;
    color: rgba($color-white, 0.7);
  }

  .trendingSearchesList {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
  }
}

.ellipsis {
  display: inline-block;

  width: 24px;
  height: 24px;
  margin-bottom: 1px;

  // Removes the whitespace below the element to stop it from overflowing the parent
  font-size: 0;
  vertical-align: middle;

  background: rgba($color-white, 0.3);
  border-radius: 50%;
}

.attribution {
  position: absolute;
  right: $space-20;
  bottom: $space-20;

  opacity: 0.7;

  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
    filter: none;
  }
}

.attributionLabel {
  opacity: 0.6;
}

.canvaLogo {
  display: block;

  width: auto;
  height: 30px;
  margin-bottom: $space-8;
  padding: $space-4;

  fill: $color-greyPlus1;
}

@use "sass:color";

@import "/styles/variables";

@mixin menu {
  @include media("mobile") {
    min-width: 0;
  }

  position: absolute;
  z-index: $z-index-dropdowns;

  overflow: auto;
  display: none;

  min-width: 300px;
  max-width: calc(100vw - #{$space-50});
  max-height: 50vh;

  background: $color-white;
  border: 1px solid $color-greyscale-400;
  border-radius: 16px;
  box-shadow: 0 8px 8px 0 rgb(25 25 25 / 4%);

  &,
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;

    &:focus {
      outline: none;
    }
  }

  @media (height <= 600px) {
    max-height: calc(50vh - #{$space-50});
  }
}

@layer utilities {
  .outerDiv {
    display: inline-block;
  }

  .fullWidth {
    display: block;
    width: 100%;

    .inlineWrapper,
    .wrapper {
      width: 100%;
    }
  }

  .wrapper {
    position: relative;
    display: inline-flex;

    &.isOpen {
      .menu {
        display: block;
      }
    }

    &:focus-within {
      border-radius: 6px;
    }
  }

  .toggleButton {
    &.active {
      background: color.mix($color-greyPlus1, $color-white, 10%);
      border-color: $color-greyPlus1;

      svg {
        fill: $color-greyPlus1;
      }
    }

    span {
      justify-content: unset;
      width: 100%;
      text-align: left;
    }
  }

  .menu {
    @include menu;
  }

  .menuNoMaxHeight {
    max-height: none;
  }

  .arrowIcon {
    align-self: flex-end;
    margin-right: -5px; // visual hack to account for gap on the side of the svg container
  }

  // Help position the selector more accurately by removing the line gap of the selector - will always be a single line
  .label * {
    white-space: nowrap;
  }
}
